import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../utils';
import Menu from './Menu/Menu';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';

let timeInterval: string | number | NodeJS.Timeout | undefined;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();
  const participants = useParticipants();
  const [orderInfo, setOrder] = useState<any>();

  async function modifyLastHour(hour: number, minute: number) {
    let minuteResult;
    let resultObj: any = {};
    return new Promise((resolve, reject) => {
      if (orderInfo?.packageType == 'free') {
        minuteResult = Number(minute + 5);
      } else {
        minuteResult = Number(minute + 15);
      }
      if (minuteResult > 60) { // yukarıdaki +15 ile 60ın üstüne çıkan dakika varsa saat atlatıp -60 yapalım.
        const minusMinute: Number = Number(minuteResult - 60);
        const resultHour = hour == 23 ? 0 : Number(hour + 1);
        resultObj.hour = resultHour;
        resultObj.minute = minusMinute;
        resolve(resultObj);
      } else {
        resultObj.hour = hour;
        resultObj.minute = minuteResult;
        resolve(resultObj);
      }
    });
  }

  const [isStarted, setStarted] = useState<Boolean>(false);
  const [currentTime, setCurrentTime] = useState<any>();
  const timerProcess = async (future: any) => {
    setStarted(true);
    timeInterval = setInterval(() => {
      var now: number = new Date().getTime();
      // Find the distance between now an the count down date
      var distance: number = Number(future - now);
      // Time calculations for days, hours, minutes and seconds
      var hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minute: any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var second: any = Math.floor((distance % (1000 * 60)) / 1000);

      let result: any = hour + ' : ' + minute + ' : ' + second;
      if ((hour == 0 && minute == 0 && second < 1) || !result) {
        room!.disconnect();
        setCurrentTime(0);
        clearInterval(timeInterval);
      } else {
        setCurrentTime(result);
      }
    }, 1000);
  };

  async function counter() {
    const forTimeT: any = new Date().toLocaleTimeString('tr-TR').split(':');

    const t = new Date().toLocaleDateString('tr-TR').split('.');
    let todayDate: any = new Date(`${t[2]}/${t[1]}/${t[0]}`);
    const od: any = orderInfo?.date?.split('-');
    let orderDate: any = new Date(Number(od[2]), Number(od[1] - 1), Number(od[0]));

    //if(todayDate.getTime() != orderDate.getTime()) alert("Randevu saati henüz gelmemiş.");
    const hourObj: any = await modifyLastHour(
      orderInfo?.hours[orderInfo?.hours?.length > 1 ? orderInfo?.hours?.length - 1 : 0]?.hour,
      orderInfo?.hours[orderInfo?.hours?.length > 1 ? orderInfo?.hours?.length - 1 : 0]?.minute
    );
    orderDate.setHours(Number(hourObj?.hour), Number(hourObj?.minute), 0);
    const todayDateTime: any = todayDate.setHours(Number(forTimeT[0]), Number(forTimeT[1]), Number(forTimeT[2]));

    const calculateSeconds: number = Number(orderDate.getTime() - todayDate.getTime());
    timerProcess(Number(orderDate.getTime()));
  }

  const getOrderDetail = async () => {
    const endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    const currentUrl: any = window.location.pathname.split('/')[2];

    const data: any = await fetch(`${endpoint}/order/get-order-for-twilio/${currentUrl}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    }).then((res: any) => res.json());
    setOrder(data?.data);
  };

  useEffect(() => {
    getOrderDetail();
  }, []);

  useEffect(() => {
    if (orderInfo?._id) {
      counter();
    }
  }, [orderInfo]);

  return (
    <>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">Ekranınız Paylaşılıyor</Typography>
          <Button onClick={() => toggleScreenShare()}>Paylaşımı Durdur</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Typography variant="body1">
                <b>Kalan Süre : </b>
                {/* Sayaç */ currentTime}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
              <Hidden smDown>
                <Menu />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <EndCallButton />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
