import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';

import { Callback } from '../../../types';

export default function useHandleRoomDisconnection(
  room: Room | null,
  onError: Callback,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void
) {
  async function disconnectReport() {
    const endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    const authToken: any = JSON.parse(`${window.localStorage.getItem('authToken')}`);
    const orderID: any = JSON.parse(`${window.localStorage.getItem('orderID')}`);
    let targetUrl;
    const data: any = await fetch(`${endpoint}/video-conferencing/disconnect-progress/`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ orderID: orderID.toString() }),
    }).then(res => res.json());

    if (data?.isExpert) {
      targetUrl = `${
        process.env.REACT_APP_FRONTEND_ENDPOINT
      }/uzman/randevularim?notifyID=${orderID.toString()}&hosted=twilio`;
    } else {
      targetUrl = `${
        process.env.REACT_APP_FRONTEND_ENDPOINT
      }/profil/randevularim?notifyID=${orderID.toString()}&hosted=twilio`;
    }
    window.location.href = `${targetUrl}`;
  }

  useEffect(() => {
    if (room) {
      const onDisconnected = async (_: Room, error: TwilioError) => {
        if (error) {
          onError(error);
        }
        removeLocalAudioTrack();
        removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }
        await disconnectReport();
      };

      room.on('disconnected', onDisconnected);
      return () => {
        room.off('disconnected', onDisconnected);
      };
    }
  }, [room, onError, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare]);
}
