import React, { useState } from 'react';
import Snackbar from '../../Snackbar/Snackbar';
import useDevices from '../../../hooks/useDevices/useDevices';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export function getSnackbarContent(hasAudio: boolean, hasVideo: boolean, error?: Error) {
  let headline = '';
  let message = '';

  switch (true) {
    // These custom errors are thrown by the useLocalTracks hook. They are thrown when the user explicitly denies
    // permission to only their camera, or only their microphone.
    case error?.message === 'CameraPermissionsDenied':
      headline = 'Unable to Access Media:';
      message = 'Videoyu kullanma iznini reddettiniz. Lütfen kameraya erişmek için tarayıcıya izin verin.';
      break;
    case error?.message === 'MicrophonePermissionsDenied':
      headline = 'Unable to Access Media:';
      message = 'Ses kullanma iznini reddettiniz. Lütfen tarayıcının mikrofona erişmesine izin verin.';
      break;

    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      headline = 'Unable to Access Media:';

      if (error!.message === 'Permission denied by system') {
        // Chrome only
        message =
          'İşletim sistemi, tarayıcının mikrofona veya kameraya erişmesini engelledi. Lütfen işletim sistemi ayarlarınızı kontrol edin.';
      } else {
        message =
          'Ses ve video kullanma iznini reddettiniz. Lütfen tarayıcının mikrofona ve kameraya erişmesine izin verin.';
      }

      break;

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      headline = 'Kamera veya Mikrofon Bulunamadı:';
      message =
        'Tarayıcınız kameranıza veya mikrofonunuza erişemedi. Lütfen cihaz bağlantıların doğru olduğunu kontrol edin veya güncel bir tarayıcı kullandığınızdan emin olun.';
      break;

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      headline = 'Error Acquiring Media:';
      message = `${error!.name} ${error!.message}`;
      break;

    case !hasAudio && !hasVideo:
      headline = 'Kamera veya Mikrofon Bulunamadı!:';
      message = 'Katılımcılar sizi duyamaz veya göremez.';
      break;

    case !hasVideo:
      headline = 'Kamera Bulunamadı!:';
      message = 'Katılımcılar görüntünüze erişim sağlayamaz.';
      break;

    case !hasAudio:
      headline = 'Mikrofon Bulunamadı!:';
      message = 'Katılımcılar sizi duyamaz.';
  }

  return {
    headline,
    message,
  };
}

export default function MediaErrorSnackbar({ error }: { error?: Error }) {
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

  const { isAcquiringLocalTracks } = useVideoContext();

  const [isSnackbarDismissed, setIsSnackbarDismissed] = useState(false);

  const isSnackbarOpen =
    !isSnackbarDismissed &&
    !isAcquiringLocalTracks &&
    (Boolean(error) || !hasAudioInputDevices || !hasVideoInputDevices);

  const { headline, message } = getSnackbarContent(hasAudioInputDevices, hasVideoInputDevices, error);

  return (
    <Snackbar
      open={isSnackbarOpen}
      handleClose={() => setIsSnackbarDismissed(true)}
      headline={headline}
      message={message}
      variant="warning"
    />
  );
}
