import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();
  const urlString: any = window.location.href;
  const query: any = new URL(urlString);
  const auth: any = query.searchParams.get('auth');
  const userID: any = query.searchParams.get('id');
  const orderID: any = query.searchParams.get('order');

  window.localStorage.setItem('authToken', JSON.stringify(decodeURIComponent(auth)));
  window.localStorage.setItem('userID', JSON.stringify(userID));
  window.localStorage.setItem('orderID', JSON.stringify(orderID));

  setName(userID);
  setRoomName(orderID);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };
  // http://localhost:3005?auth=U2FsdGVkX19y9PrSBcWvizo3mRrnG3cHqiBwqfGOoy+4gPIENqkf1oq9mN0EEqU9o8EOnJfqpuWdkvPKxtC/t7rLjIBl+PYWXKcub5rnwFzQ97170e3gunlhVLGpprF81C80ZOy51LMbGwcYQgSSzn7SpyRjcuJnIxv491d3Ny9YB8Jrb9pOi3mMIO7QV3OYmGDLETVBYPNqi6xO3vVLKjDXtorM6reBLiMjUh/EozsodKze/OaizLP4+0x/qJtQ&id=62972bc5601bdeb54f33e866&order=6308ec048f45ef0026990ceb
  const hasUsername = !auth || !userID || !orderID;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Konferansa Katılın
      </Typography>
      <Typography variant="body1">
        {hasUsername
          ? 'Lütfen bağlantı adresinizin doğruluğundan emin olun. Randevu bilgilerinize erişim sağlayamadık.'
          : 'Konferansa katılmak için her şey hazır görünüyor. Aşağıdaki butonu kullanarak başlayabiliriz.'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="flex-start">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={hasUsername}
            className={classes.continueButton}
          >
            Hazırım, Başlayalım.
          </Button>
        </Grid>
      </form>
    </>
  );
}
