import React, { useState, useEffect } from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant } from 'twilio-video';

interface ParticipantProps {
  participant: IParticipant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
}

export function Participant({
  participant,
  videoOnly,
  enableScreenShare,
  onClick,
  isSelected,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker,
}: ParticipantProps) {
  const [userInfo, setUserInfo] = useState<any>({});
  const getUserInfo = async () => {
    const endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    const currentUrl: any = window.location.pathname.split('/')[2];
    const data: any = await fetch(`${endpoint}/user/get-user-for-twilio/${participant.identity}/${currentUrl}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    }).then((res: any) => res.json());
    setUserInfo(data?.data);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <ParticipantInfo
      participant={participant}
      userInfo={userInfo}
      onClick={onClick}
      isSelected={isSelected}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
      isDominantSpeaker={isDominantSpeaker}
    >
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        enableScreenShare={enableScreenShare}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  );
}

export default React.memo(Participant);
